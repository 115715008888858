/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';

const Quote = ({ children }) => {
  const quoteRegExp = new RegExp(/_([^_]+)_/);
  return (
    <h2
      sx={{
        variant: 'text.quote.text',
        em: { variant: 'text.quote.em' },
      }}
    >
      {reactStringReplace(children, quoteRegExp, (match, j) => (
        <em key={j}>{match}</em>
      ))}
    </h2>
  );
};

Quote.propTypes = {
  children: PropTypes.string,
};

const StyledQuoteBlock = styled.div`
  padding: 100px 50px;
  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-area: quote;
`;

const QuoteColorBlocks = ({ scale = 'accent' }) =>
  new Array(4).fill('your fav could nevuh').map((x, i) => (
    <div
      key={i}
      sx={{
        gridArea: `block-${i + 1}`,
        bg: theme => {
          if (scale === 'primary') {
            return `${theme.colors.scale.primary[i]}`;
          }
          if (scale === 'secondary') {
            return `${theme.colors.scale.secondary[i]}`;
          }
          if (scale === 'tertiary') {
            return `${theme.colors.scale.tertiary[i]}`;
          }
          if (scale === 'accent') {
            return `${theme.colors.scale.accent[i]}`;
          }
        },
      }}
    />
  ));

QuoteColorBlocks.propTypes = {
  scale: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'accent']),
};

// export { StyledQuoteBlock, QuoteBlock, QuoteColorBlocks };
export { StyledQuoteBlock, QuoteColorBlocks };
export default Quote;
