/** @jsx jsx */
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import QuoteGrid from './QuoteGrid';
import Quote, { StyledQuoteBlock, QuoteColorBlocks } from './Quote';

const StyledQuoteSection = styled.div`
  background: ${props => props.theme.colors.background};
`;

// dark/light
// alt layout
// quote
// author
// scale

const QuoteSection = ({ copy, author, scale: colorScale = 'primary', altLayout = false, darkMode }) => (
  <StyledQuoteSection sx={{ bg: `${darkMode ? 'text' : 'background'}` }}>
    <QuoteGrid invertLayout={altLayout}>
      <StyledQuoteBlock sx={{ variant: `${darkMode ? '' : 'invert'}` }}>
        <Quote>{copy}</Quote>
        <h4 sx={{ variant: 'text.quote.author' }}>{author}</h4>
      </StyledQuoteBlock>
      <QuoteColorBlocks scale={colorScale} />
    </QuoteGrid>
  </StyledQuoteSection>
);

QuoteSection.propTypes = {
  copy: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  scale: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'accent']).isRequired,
  altLayout: PropTypes.bool,
  darkMode: PropTypes.bool,
};

export default QuoteSection;
