/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import media from './media';

export const StyledQuoteSection = styled.div`
  background: ${props => props.theme.colors.background};
`;

const BaseQuoteGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 33.333vw auto repeat(2, 33.333vw);

  /* 768 */
  ${media.md`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 16.667vh auto repeat(2, 16.667vh);
  `}

  ${media.lg`
    /* Grid Styles */
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 210px);
    grid-template-rows: repeat(2, 25vh);
    
    /* Max Width */
    /* max-width: 1400px;
    max-height: 900px; */
    margin: 0 auto;
  `}

  /* ${media.xl`
    grid-template-rows: repeat(2, 225px);
  `} */
`;

const StyledQuoteGrid = styled(BaseQuoteGrid)`
  
  /* Default Variation */
  grid-template-areas:
    'block-1 . . '
    'quote quote quote'
    '. block-3 block-2'
    '. . block-4';

  ${media.md`
    grid-template-areas:
      'block-1 . . .'
      'quote quote quote quote'
      '. . block-3 block-2'
      '. . . block-4';
  `}

  ${media.lg`
    grid-template-areas: 
    '. quote quote block-3 .'
    'block-1 quote quote block-2 block-4';
  `}
  
  /* Alternate Layout */
  ${props =>
    props.invertLayout &&
    css`
      grid-template-rows: auto repeat(2, 33.333vw);
      grid-template-areas:
        'quote quote quote'
        'block-1 block-2 .'
        'block-3 . .';

      ${media.md`
          grid-template-areas:
            '. . . block-4'
            'quote quote quote quote'
            'block-1 block-2 . .'
            'block-3 . . .';
        `}

      ${media.lg`
        grid-template-areas: 
          'block-1 block-3 quote quote .'
          '. block-2 quote quote block-4';
      `}
    `}
`;

export default StyledQuoteGrid;
