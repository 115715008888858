import React from 'react';
import PropTypes from 'prop-types';
import { Styled } from 'theme-ui';

const HeaderCopyLockup = ({ title, children }) => (
  <div className="column">
    <Styled.h3>{title}</Styled.h3>
    <Styled.p>{children}</Styled.p>
  </div>
);

HeaderCopyLockup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default HeaderCopyLockup;
