/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import media from './media'
import GenericWrapper from './Wrapper'
import HeaderCopyLockup from './HeaderyCopy'

const StyledTwoThirdsGrid = styled.div`
  margin: 80px 0;
  display: grid;
  grid-template-columns: auto;

  ${media.lg`
    margin: 120px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: 'small large';

    .column--small {
      grid-area: 'small';
      h2 {
        max-width: 52%;
      }
    }

    .column--large {
      grid-area: 'large';
    }
  `}

  ${props =>
    props.debug &&
    css`
      .column--small {
        background: papayawhip;
      }
      .column--large {
        background: tomato;
      }
    `}
`

const PhiloSubGrid = styled.div`
  display: grid;

  ${media.lg`
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 51px;
    grid-row-gap: 40px;
    .column-wide {
      grid-column: 1 / span 3;
    }
  `}
`

const ProductSubGrid = styled.div`
  display: grid;
  ${media.lg`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 50px;
    grid-row-gap: 52px;
  `}
`

export { StyledTwoThirdsGrid, PhiloSubGrid, ProductSubGrid }

const TextBlockMap = ({ blocks }) => (
  <>
    {blocks.map((x, i) => (
      <HeaderCopyLockup key={i} title={x.headline}>
        {x.copy}
      </HeaderCopyLockup>
    ))}
  </>
)

// TextBlockMap.propTypes

const TextSectionChildren = ({ children, subHeadline, blocks }) => (
  <>
    {subHeadline && (
      <div className="column-wide">
        <Styled.p>{subHeadline}</Styled.p>
      </div>
    )}
    {children}
    {blocks && <TextBlockMap blocks={blocks} />}
  </>
)

export const ThreeColumnGridWrapper = styled(GenericWrapper)`
  padding-top: 80px;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: auto;
  border-color: inherit;
  position: relative;
  /* background-color: rgba(255, 100, 0, 0.1); */

  ${media.md`
    padding-top: 80px;
    padding-bottom: 80px;
  `}

  ${media.lg`
    padding-top: 120px;
    padding-bottom: 120px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 55px;
  `}
`

const TextSection = ({
  title,
  children,
  alternateSubGrid,
  subHeadline,
  blocks,
}) => {
  const foo = 'foo'
  return (
    <GenericWrapper className="sectionHook">
      <StyledTwoThirdsGrid>
        {/* small column, only title */}
        <div className="column--small">
          <Styled.h2>{title}</Styled.h2>
        </div>

        {/* Large Column */}
        <div className="column--large">
          {alternateSubGrid ? (
            <PhiloSubGrid>
              <TextSectionChildren subHeadline={subHeadline} blocks={blocks}>
                {children}
              </TextSectionChildren>
            </PhiloSubGrid>
          ) : (
            <ProductSubGrid>
              <TextSectionChildren subHeadline={subHeadline} blocks={blocks}>
                {children}
              </TextSectionChildren>
            </ProductSubGrid>
          )}
        </div>
      </StyledTwoThirdsGrid>
    </GenericWrapper>
  )
}

export const TextSectionGeneric = ({
  title,
  children,
  subHeadline,
  blocks,
}) => {
  const foo = 'foo'
  return (
    <ThreeColumnGridWrapper className="sectionHook">
      {/* small column, only title */}
      <div>
        <Styled.h2>{title}</Styled.h2>
      </div>
      {/* Divide up the remaining two columns */}
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: ['auto', 'auto', 'repeat(2, 1fr)'],
          gridGap: ['0', '0', '55px'],
        }}
      >
        {children}
        {/* Large Column */}
        {!children && (
          <TextSectionChildren subHeadline={subHeadline} blocks={blocks}>
            {children}
          </TextSectionChildren>
        )}
      </div>
    </ThreeColumnGridWrapper>
  )
}

export default TextSection
